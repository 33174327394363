import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Portfolio from './pages/Portfolio';
import Service from './pages/Service';
import Career from './pages/Career';
import AboutUs from './pages/AboutUs';
import PortfolioAnandsweets from './pages/PortfolioAnandsweets';
import ServiceWebDevelopment from './pages/ServiceWebDevelopment';
import ServiceECommerce from './pages/ServiceECommerce';
import ServiceVisualIdentity from './pages/ServiceVisualIdentity';
import PortfolioYogabar from './pages/PortfolioYogabar';
import PortfolioNourysh from './pages/PortfolioNourysh';
import PortfolioGiverNutritions from './pages/PortfolioGiverNutritions';
import NotFound from './pages/NotFound';
import ServiceSEO from './pages/ServiceSEO';
import ServiceUiUx from './pages/ServiceUiUx';
import ServiceWebDesign from './pages/ServiceWebDesign';
import ServiceForStartup from './pages/ServiceForStartup';
import ServiceAbTesting from './pages/ServiceAbTesting';
import PortfolioChhappanbhog from './pages/PortfolioChhappanbhog';
import PortfolioBayla from './pages/PortfolioBayla';
import PortfolioDr_vaidya from './pages/PortfolioDr_vaidya';
import Clients from './pages/Clients';
import PortfolioPrakruti from './pages/PortfolioPrakruti';
import PortfolioHyFun from './pages/PortfolioHyFun';
import PortfolioHamdard from './pages/PortfolioHamdard';
import PortfolioThenga from './pages/PortfolioThenga';
import PortfolioSportSaber from './pages/PortfolioSportSaber';
import PortfolioShistaka from './pages/PortfolioShistaka';
import Blogs from './pages/Blogs';
import BlogDetail from './pages/BlogDetail';
import AboxBranding from './pages/AboxBranding';
import PortfolioDLFilms from './pages/PortfolioDLFilms';
import PortfolioUbar from './pages/PortfolioUbar';
import PortfolioRadheySweet from './pages/PortfolioRadheySweet';
import PortfolioFreshLeaf from './pages/PortfolioFreshLeaf';
import PortfolioHealthoxide from './pages/PortfolioHealthoxide';
import InternationalCampaign from './pages/InternationalCampaign';
import Service3DRender from './pages/Service3DRender';
import InternationalCampaignB from './pages/InternationalCampaignB';
import Lenis from '@studio-freight/lenis';
import PortfolioMaaNBaby from './pages/PortfolioMaaNBaby';
import PortfolioGoGrub from './pages/PortfolioGoGrub';
import PortfolioEkkiBekki from './pages/PortfolioEkkiBekki';
import LegalBranding from './pages/LegalBranding';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PortfolioMyPro from './pages/PortfolioMyPro';
import PortfolioTeaToxLife from './pages/PortfolioTeaToxLife';
import ConversionRateOptimization from './pages/ConversionRateOptimization';
import PortfolioAumex from './pages/PortfolioAumex';
import PortfolioGreenFay from './pages/PortfolioGreenFay';
import UITeatoxLife from './pages/ui/UITeatoxLife';
import IndiaCampaign from './pages/IndiaCampaign';
import ThankYou from './pages/ThankYou';
import UITheOne from './pages/ui/UITheOne';
import LabelShishtaka from './pages/label/LabelShishtaka';
import LabelHealingHands from './pages/label/LabelHealingHands';
import LabelNaturare from './pages/label/LabelNaturare';
import LabelMyPro from './pages/label/LabelMyPro';
import LabelAumex from './pages/label/LabelAumex';
import LabelTeatoxLife from './pages/label/LabelTeatoxLife';
import PortfolioPaperClipNCo from './pages/PortfolioPaperClipNCo';
import PortfolioNaturare from './pages/PortfolioNaturare';
import PortfolioFastNFry from './pages/PortfolioFastNFry';
import PortfolioNeoRoots from './pages/PortfolioNeoRoots';
import UIAppleElevators from './pages/ui/UIAppleElevators';
import UILycanPay from './pages/ui/UILycanPay';
import UIHealingHands from './pages/ui/UIHealingHands';
import PortfolioWithin from './pages/PortfolioWithin';
import PortfolioUppercase from './pages/PortfolioUppercase';
import PortfolioOmSweets from './pages/PortfolioOmSweets';
import PortfolioHealingHands from './pages/PortfolioHealingHands';
import PortfolioYoGathiya from './pages/PortfolioYoGathiya';
import PortfolioCodifi from './pages/PortfolioCodifi';
import PortfolioTerahash from './pages/PortfolioTerahash';
import PortfolioKutum from './pages/PortfolioKutum';
import PortfolioArospice from './pages/PortfolioArospice';
import TerahashBranding from './pages/TerahashBranding';
import LabelRas from './pages/label/LabelRas';
import PortfolioFoneNetwork from './pages/PortfolioFoneNetwork';
import PortfolioRepeatGud from './pages/PortfolioRepeatGud';
import PortfolioNurdle from './pages/PortfolioNurdle';
import NouryshBranding from './pages/NouryshBranding';
import PortfolioAuroLab from './pages/PortfolioAuroLab';
import LabelMoody from './pages/label/LabelMoody';
import PortfolioTechnoSport from './pages/PortfolioTechnoSport';
import PortfolioWesterville from './pages/PortfolioWesterville';
import PortfolioMyoaCookies from './pages/PortfolioMyoaCookies';
import PortfolioSparkFusion from './pages/PortfolioSparkFusion';
import PortfolioBontrue from './pages/PortfolioBontrue';
import PortfolioBhagavadGita from './pages/PortfolioBhagavadGita';
import LabelNourysh from './pages/label/LabelNourysh';
import PortfolioWeightManage from './pages/PortfolioWeightManage';
import PortfolioAgarwalBhavanSweets from './pages/PortfolioAgarwalBhavanSweets';
import PortfolioMurariSweet from './pages/PortfolioMurariSweet';
import PortfolioSinglas from './pages/PortfolioSinglas';
import PortfolioSavaniJewellery from './pages/PortfolioSavaniJewellery';
import PortfolioBodyArt from './pages/PortfolioBodyArt';
import PortfolioMia from './pages/PortfolioMia';
import PortfolioGPullaReddySweets from './pages/PortfolioGPullaReddySweets';
import PortfolioChakNow from './pages/PortfolioChakNow';
import PortfolioVijayaLaxmiSilk from './pages/PortfolioVijayaLaxmiSilk';
import PortfolioInstantCashPrice from './pages/PortfolioInstantCashPrice';
import PortfolioRedPalette from './pages/PortfolioRedPalette';

function App() {

  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.5,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

  }, [])

  return (
    <>
      <div id='disablescroll'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/service" element={<Service />} />
          <Route path="/career" element={<Career />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/clients" element={<Clients />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path={'/blogs/:title'} element={<BlogDetail />} />

          <Route path="/portfolio/anand-sweets" element={<PortfolioAnandsweets />} />
          <Route path="/portfolio/yogabar" element={<PortfolioYogabar />} />
          <Route path="/portfolio/nourysh" element={<PortfolioNourysh />} />
          <Route path="/portfolio/dr-vaidya" element={<PortfolioDr_vaidya />} />
          <Route path="/portfolio/giver-nutritions" element={<PortfolioGiverNutritions />} />
          <Route path="/portfolio/bayla" element={<PortfolioBayla />} />
          <Route path="/portfolio/chhappanbhog" element={<PortfolioChhappanbhog />} />
          <Route path="/portfolio/prakruti" element={<PortfolioPrakruti />} />
          <Route path="/portfolio/hyfun" element={<PortfolioHyFun />} />
          <Route path="/portfolio/hamdard" element={<PortfolioHamdard />} />
          <Route path="/portfolio/thenga" element={<PortfolioThenga />} />
          <Route path="/portfolio/shistaka" element={<PortfolioShistaka />} />
          <Route path="/portfolio/sportsaber" element={<PortfolioSportSaber />} />
          <Route path="/portfolio/dl-films" element={<PortfolioDLFilms />} />
          <Route path="/portfolio/ubar" element={<PortfolioUbar />} />
          <Route path="/portfolio/radhey-sweets" element={<PortfolioRadheySweet />} />
          <Route path="/portfolio/freshleaf" element={<PortfolioFreshLeaf />} />
          <Route path="/portfolio/healthoxide" element={<PortfolioHealthoxide />} />
          <Route path="/portfolio/maa-and-baby" element={<PortfolioMaaNBaby />} />
          <Route path="/portfolio/gogrub" element={<PortfolioGoGrub />} />
          <Route path="/portfolio/mypro" element={<PortfolioMyPro />} />
          <Route path="/portfolio/teatox-life" element={<PortfolioTeaToxLife />} />
          <Route path="/portfolio/ekki-bekki" element={<PortfolioEkkiBekki />} />
          <Route path="/portfolio/aumex" element={<PortfolioAumex />} />
          <Route path="/portfolio/greenfay" element={<PortfolioGreenFay />} />
          <Route path="/portfolio/paperclip-and-co" element={<PortfolioPaperClipNCo />} />
          <Route path="/portfolio/naturare" element={<PortfolioNaturare />} />
          <Route path="/portfolio/fast-n-fry" element={<PortfolioFastNFry />} />
          <Route path="/portfolio/neo-roots" element={<PortfolioNeoRoots />} />
          <Route path="/portfolio/within-beauty" element={<PortfolioWithin />} />
          <Route path="/portfolio/uppercase" element={<PortfolioUppercase />} />
          <Route path="/portfolio/om-sweets" element={<PortfolioOmSweets />} />
          <Route path="/portfolio/healing-hands" element={<PortfolioHealingHands />} />
          <Route path="/portfolio/yo-gathiya" element={<PortfolioYoGathiya />} />
          <Route path="/portfolio/codifi" element={<PortfolioCodifi />} />
          <Route path="/portfolio/terahash-solutions" element={<PortfolioTerahash />} />
          <Route path="/portfolio/kutum-ayurveda" element={<PortfolioKutum />} />
          <Route path="/portfolio/arospice" element={<PortfolioArospice />} />
          <Route path="/portfolio/fone-network" element={<PortfolioFoneNetwork />} />
          <Route path="/portfolio/repeat-gud" element={<PortfolioRepeatGud />} />
          <Route path="/portfolio/nurdle" element={<PortfolioNurdle />} />
          <Route path="/portfolio/aurolab" element={<PortfolioAuroLab />} />
          <Route path="/portfolio/technosport" element={<PortfolioTechnoSport />} />
          <Route path="/portfolio/westerville" element={<PortfolioWesterville />} />
          <Route path="/portfolio/myoa-cookies" element={<PortfolioMyoaCookies />} />
          <Route path="/portfolio/spark-fusion" element={<PortfolioSparkFusion />} />
          <Route path="/portfolio/bontrue-furniture" element={<PortfolioBontrue />} />
          <Route path="/portfolio/bhagavad-gita" element={<PortfolioBhagavadGita />} />
          <Route path="/portfolio/weight-manage" element={<PortfolioWeightManage />} />
          <Route path="/portfolio/agarwalbhavan-sweets" element={<PortfolioAgarwalBhavanSweets />} />
          <Route path="/portfolio/murari-sweets" element={<PortfolioMurariSweet />} />
          <Route path="/portfolio/singlas-sweets" element={<PortfolioSinglas />} />
          <Route path="/portfolio/savani-jewellery" element={<PortfolioSavaniJewellery />} />
          <Route path="/portfolio/bodyart" element={<PortfolioBodyArt />} />
          <Route path="/portfolio/miamyally" element={<PortfolioMia />} />
          <Route path="/portfolio/gpullareddysweets" element={<PortfolioGPullaReddySweets />} />
          <Route path="/portfolio/chaknow" element={<PortfolioChakNow />} />         
          <Route path="/portfolio/vijayalakshmisilks" element={<PortfolioVijayaLaxmiSilk />} /> 
          <Route path="/portfolio/instantcashprice" element={<PortfolioInstantCashPrice />} /> 
          <Route path="/portfolio/myredpalette" element={<PortfolioRedPalette />} />       

          <Route path="/portfolio/ui/teatox-life" element={<UITeatoxLife />} />
          <Route path="/portfolio/ui/the-one" element={<UITheOne />} />
          <Route path="/portfolio/ui/apple-elevators" element={<UIAppleElevators />} />
          <Route path="/portfolio/ui/lycanpay" element={<UILycanPay />} />
          <Route path="/portfolio/ui/healing-hands" element={<UIHealingHands />} />

          <Route path="/portfolio/label/shishtaka" element={<LabelShishtaka />} />
          <Route path="/portfolio/label/healing-hands" element={<LabelHealingHands />} />
          <Route path="/portfolio/label/naturare" element={<LabelNaturare />} />
          <Route path="/portfolio/label/mypro" element={<LabelMyPro />} />
          <Route path="/portfolio/label/aumex" element={<LabelAumex />} />
          <Route path="/portfolio/label/teatox-life" element={<LabelTeatoxLife />} />
          <Route path="/portfolio/label/ras-beauty" element={<LabelRas />} />
          <Route path="/portfolio/label/nourysh" element={<LabelNourysh />} />
          <Route path="/portfolio/label/moody" element={<LabelMoody />} />

          <Route path="/portfolio/branding/abox" element={<AboxBranding />} />
          <Route path="/portfolio/branding/terahash-solutions" element={<TerahashBranding />} />
          <Route path="/portfolio/branding/nourysh" element={<NouryshBranding />} />

          <Route path="/international-campaign" element={<InternationalCampaign />} />
          <Route path="/international-campaign-b" element={<InternationalCampaignB />} />

          <Route path="/services/seo" element={<ServiceSEO />} />
          <Route path="/services/web-development" element={<ServiceWebDevelopment />} />
          <Route path="/services/shopify" element={<ServiceECommerce />} />
          <Route path="/services/visual-identity" element={<ServiceVisualIdentity />} />
          <Route path="/services/ui-ux" element={<ServiceUiUx />} />
          <Route path="/services/web-design" element={<ServiceWebDesign />} />
          <Route path="/services/for-startup" element={<ServiceForStartup />} />
          <Route path="/services/ab-testing" element={<ServiceAbTesting />} />
          <Route path="/services/3d-render" element={<Service3DRender />} />

          <Route path="/legal" element={<LegalBranding />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/conversion-rate-optimization" element={<ConversionRateOptimization />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/india-campaign" element={<IndiaCampaign />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </>
  );
}

export default App;