export const backgroundColors = {
    "/portfolio/anand-sweets": "#1B1830",
    "/portfolio/yogabar": "#0C052B",
    "/portfolio/nourysh": "#01191F",
    "/portfolio/dr-vaidya": "#083544",
    "/portfolio/giver-nutritions": "#2E170A",
    "/portfolio/bayla": "#2C3001",
    "/portfolio/chhappanbhog": "#171C26",
    "/portfolio/prakruti": "#022816",
    "/portfolio/hyfun": "#102602",
    "/portfolio/hamdard": "#2E0304",
    "/portfolio/thenga": "#231302",
    "/portfolio/shistaka": "#241B03",
    "/portfolio/sportsaber": "#260C04",
    "/portfolio/dl-films": "#171717",
    "/portfolio/ubar": "#401603",
    "/portfolio/freshleaf": "#212721",
    "/portfolio/radhey-sweets": "#04272E",
    "/portfolio/healthoxide": "#052F46",
    "/portfolio/maa-and-baby": "#014263",
    "/portfolio/gogrub": "#031929",
    "/portfolio/ekki-bekki": "#07313C",
    "/portfolio/mypro": "#161616",
    "/portfolio/teatox-life": "#0D2318",
    "/portfolio/aumex": "#050121",
    "/portfolio/greenfay": "#2E0B01",
    "/portfolio/paperclip-and-co": "#040328",
    "/portfolio/naturare": "#011A0D",
    "/portfolio/fast-n-fry": "#130F0F",
    "/portfolio/neo-roots": "#14332C",
    "/portfolio/within-beauty": "#412382",
    "/portfolio/uppercase": "#063541",
    "/portfolio/om-sweets": "#C01F25",
    "/portfolio/healing-hands": "#032C2F",
    "/portfolio/yo-gathiya": "#7C0A21",
    "/portfolio/codifi": "#311367",
    "/portfolio/terahash-solutions": "#0F0F0F",
    "/portfolio/kutum-ayurveda": "#975C24",
    "/portfolio/arospice": "#204F34",
    "/portfolio/fone-network": "#07083A",
    "/portfolio/repeat-gud": "#131313",
    "/portfolio/nurdle": "#1E2B3C",
    "/portfolio/aurolab": "#005268",
    "/portfolio/technosport": "#1B1B1B",
    "/portfolio/westerville": "#202C39",
    "/portfolio/myoa-cookies": "#29120F",
    "/portfolio/spark-fusion": "#151213",
    "/portfolio/bontrue-furniture": "#280E0A",
    "/portfolio/bhagavad-gita": "#451018",
    "/portfolio/weight-manage": "#262626",
    "/portfolio/agarwalbhavan-sweets": "#171616",
    "/portfolio/murari-sweets": "#192658",
    "/portfolio/singlas-sweets": "#230E0F",
    "/portfolio/savani-jewellery": "#E6BB8A",
    "/portfolio/bodyart": "#181A1F",
    "/portfolio/miamyally": "#3A1B59",
    "/portfolio/gpullareddysweets": "#111",
    "/portfolio/chaknow": "#1A1617",
    "/portfolio/vijayalakshmisilks": "#19181A",
    "/portfolio/instantcashprice": "#171A29",
    "/portfolio/myredpalette": "#121212",
    "/portfolio": "#222",
    "/portfolio/branding/abox": "#222222",
    "/portfolio/branding/terahash-solutions": "#0F0F0F",
    "/portfolio/branding/nourysh": "#36386D",
}

export const fontColors = {
    "/portfolio/anand-sweets": "#C9C5E6",
    "/portfolio/yogabar": "#E3DCFF",
    "/portfolio/nourysh": "#FFFFFF",
    "/portfolio/dr-vaidya": "#BDF0FF",
    "/portfolio/giver-nutritions": "#FFFFFF",
    "/portfolio/bayla": "#FFFFFF",
    "/portfolio/chhappanbhog": "#BED2FF",
    "/portfolio/prakruti": "#90E3BD",
    "/portfolio/hyfun": "#DEFBCC",
    "/portfolio/hamdard": "#FBE5E6",
    "/portfolio/thenga": "#EDEAE0",
    "/portfolio/shistaka": "#F2E2BA",
    "/portfolio/sportsaber": "#efebe7",
    "/portfolio/dl-films": "#FFFFFF",
    "/portfolio/ubar": "#FFFFFF",
    "/portfolio/freshleaf": "#FFFFFF",
    "/portfolio/radhey-sweets": "#FFFFFF",
    "/portfolio/healthoxide": "#FFFFFF",
    "/portfolio/maa-and-baby": "#E8FAFF",
    "/portfolio/gogrub": "#E8FAFF",
    "/portfolio/ekki-bekki": "#E8FAFF",
    "/portfolio/mypro": "#E8FAFF",
    "/portfolio/teatox-life": "#E8FAFF",
    "/portfolio/aumex": "#E8FAFF",
    "/portfolio/greenfay": "#E8FAFF",
    "/portfolio/paperclip-and-co": "#E8FAFF",
    "/portfolio/naturare": "#E8FAFF",
    "/portfolio/fast-n-fry": "#E8FAFF",
    "/portfolio/neo-roots": "#E8FAFF",
    "/portfolio/within-beauty": "#E3DCFF",
    "/portfolio/uppercase": "#D1F2F8",
    "/portfolio/om-sweets": "#FBF4EC",
    "/portfolio/healing-hands": "#FFFFFF",
    "/portfolio/yo-gathiya": "#FFFFFF",
    "/portfolio/codifi": "#FFFFFF",
    "/portfolio/terahash-solutions": "#FFFFFF",
    "/portfolio/kutum-ayurveda": "#FFFFFF",
    "/portfolio/arospice": "#FFFFFF",
    "/portfolio/fone-network": "#FFFFFF",
    "/portfolio/repeat-gud": "#FFFFFF",
    "/portfolio/nurdle": "#FFFFFF",
    "/portfolio/aurolab": "#FFFFFF",
    "/portfolio/technosport": "#FFFFFF",
    "/portfolio/westerville": "#FFFFFF",
    "/portfolio/myoa-cookies": "#FFFFFF",
    "/portfolio/spark-fusion": "#FFFFFF",
    "/portfolio/bontrue-furniture": "#FFFFFF",
    "/portfolio/bhagavad-gita": "#FFFFFF",
    "/portfolio/weight-manage": "#FFFFFF",
    "/portfolio/agarwalbhavan-sweets": "#FFFFFF",
    "/portfolio/murari-sweets": "#FFFFFF",
    "/portfolio/singlas-sweets": "#FFFFFF",
    "/portfolio/savani-jewellery": "#4D4D4D",
    "/portfolio/bodyart": "#D4D7DA",
    "/portfolio/miamyally": "#F5ECFF",
    "/portfolio/gpullareddysweets": "#F3F3F3",
    "/portfolio/chaknow": "#D2D2D2",
    "/portfolio/vijayalakshmisilks": "#D2D2D2",
    "/portfolio/instantcashprice": "#C7CADB",
    "/portfolio/myredpalette": "#F3F3F3",
    "/portfolio": "#EFEBE7",
    "/portfolio/branding/abox": "#ffffff",
    "/portfolio/branding/terahash-solutions": "#C9C5E6",
    "/portfolio/branding/nourysh": "#C9C5E6",
    "/conversion-rate-optimizations": "#004737",
}

export const buttonColors = {
    "/portfolio/anand-sweets": "#9C8EFF",
    "/portfolio/yogabar": "#A1A7FF",
    "/portfolio/nourysh": "#E1E4FF",
    "/portfolio/dr-vaidya": "#BDF0FF",
    "/portfolio/giver-nutritions": "#FFD4AC",
    "/portfolio/bayla": "#EFFFBE",
    "/portfolio/chhappanbhog": "#BED2FF",
    "/portfolio/prakruti": "#90E3BD",
    "/portfolio/hyfun": "#DEFBCC",
    "/portfolio/hamdard": "#FBE5E6",
    "/portfolio/thenga": "#EDEAE0",
    "/portfolio/shistaka": "#F2E2BA",
    "/portfolio/sportsaber": "#EF6940",
    "/portfolio/dl-films": "#F5F1EB",
    "/portfolio/ubar": "#F5D8CB",
    "/portfolio/freshleaf": "#F5FAE2",
    "/portfolio/radhey-sweets": "#FFFBD2",
    "/portfolio/healthoxide": "#E8FAFF",
    "/portfolio/maa-and-baby": "#E8FAFF",
    "/portfolio/gogrub": "#E8FAFF",
    "/portfolio/ekki-bekki": "#E8FAFF",
    "/portfolio/mypro": "#E8FAFF",
    "/portfolio/teatox-life": "#E8FAFF",
    "/portfolio/aumex": "#E8FAFF",
    "/portfolio/greenfay": "#E8FAFF",
    "/portfolio/paperclip-and-co": "#E8FAFF",
    "/portfolio/naturare": "#E8FAFF",
    "/portfolio/fast-n-fry": "#E8FAFF",
    "/portfolio/neo-roots": "#E8FAFF",
    "/portfolio/within-beauty": "#A976FB",
    "/portfolio/uppercase": "#8AC5CF",
    "/portfolio/om-sweets": "#E89580",
    "/portfolio/healing-hands": "#AAF9FF",
    "/portfolio/yo-gathiya": "#932138",
    "/portfolio/codifi": "#A38DC9",
    "/portfolio/terahash-solutions": "#2E2E2E",
    "/portfolio/kutum-ayurveda": "#C29468",
    "/portfolio/arospice": "#69917A",
    "/portfolio/fone-network": "#2B2DAE",
    "/portfolio/repeat-gud": "#373737",
    "/portfolio/nurdle": "#808B9A",
    "/portfolio/aurolab": "#4C94A7",
    "/portfolio/technosport": "#595959",
    "/portfolio/westerville": "#657789",
    "/portfolio/myoa-cookies": "#8C705F",
    "/portfolio/spark-fusion": "#7B7879",
    "/portfolio/bontrue-furniture": "#5B413D",
    "/portfolio/bhagavad-gita": "#641622",
    "/portfolio/weight-manage": "#424242",
    "/portfolio/agarwalbhavan-sweets": "#4D4949",
    "/portfolio/murari-sweets": "#475DB6",
    "/portfolio/singlas-sweets": "#3D1F21",
    "/portfolio/savani-jewellery": "#4D4D4D",
    "/portfolio/bodyart": "#5C6271",
    "/portfolio/miamyally": "#8B63B2",
    "/portfolio/gpullareddysweets": "#424242",
    "/portfolio/chaknow": "#292526",
    "/portfolio/vijayalakshmisilks": "#353132",
    "/portfolio/instantcashprice": "#252941",
    "/portfolio/myredpalette": "#424242",

    "/services/shopify": "#222222",
    "/services/ui-ux": "#222222",
    "/services/visual-identity": "#222222",
    "/services/web-design": "#222222",
    "/services/web-development": "#222222",
    "/services/seo": "#222222",
    "/services/for-startup": "#222222",
    "/services/ab-testing": "#222222",
    "/services/3d-render": "#222222",
    "/portfolio": "#EFEBE7",
    "/portfolio/branding/abox": "#ffffff",
    "/portfolio/branding/terahash-solutions": "#2E2E2E",
    "/portfolio/branding/nourysh": "#898CE2",
    "/conversion-rate-optimization": "#004737",
}

export const buttonFontColors = {
    "/portfolio/anand-sweets": "#FFFFFF",
    "/portfolio/yogabar": "#FFFFFF",
    "/portfolio/nourysh": "#222",
    "/portfolio/dr-vaidya": "#083544",
    "/portfolio/giver-nutritions": "#112E3B",
    "/portfolio/bayla": "#2C3001",
    "/portfolio/chhappanbhog": "#161920",
    "/portfolio/prakruti": "#1B1830",
    "/portfolio/hyfun": "#1B1830",
    "/portfolio/hamdard": "#1B1830",
    "/portfolio/thenga": "#1B1830",
    "/portfolio/shistaka": "#1B1830",
    "/portfolio/dl-films": "#222222",
    "/portfolio/ubar": "#401603",
    "/portfolio/freshleaf": "#212721",
    "/portfolio/radhey-sweets": "#04272E",
    "/portfolio/healthoxide": "#052F46",
    "/portfolio/maa-and-baby": "#222222",
    "/portfolio/gogrub": "#222222",
    "/portfolio/ekki-bekki": "#222222",
    "/portfolio/mypro": "#222222",
    "/portfolio/teatox-life": "#222222",
    "/portfolio/aumex": "#222222",
    "/portfolio/greenfay": "#222222",
    "/portfolio/paperclip-and-co": "#222222",
    "/portfolio/naturare": "#222222",
    "/portfolio/fast-n-fry": "#222222",
    "/portfolio/neo-roots": "#222222",
    "/portfolio/within-beauty": "#FFFFFF",
    "/portfolio/uppercase": "#FFFFFF",
    "/portfolio/om-sweets": "#FFFFFF",
    "/portfolio/healing-hands": "#032C2F",
    "/portfolio/yo-gathiya": "#FFFFFF",
    "/portfolio/codifi": "#FFFFFF",
    "/portfolio/terahash-solutions": "#FFFFFF",
    "/portfolio/kutum-ayurveda": "#FFFFFF",
    "/portfolio/arospice": "#FFFFFF",
    "/portfolio/fone-network": "#FFFFFF",
    "/portfolio/repeat-gud": "#FFFFFF",
    "/portfolio/nurdle": "#FFFFFF",
    "/portfolio/aurolab": "#FFFFFF",
    "/portfolio/technosport": "#FFFFFF",
    "/portfolio/westerville": "#FFFFFF",
    "/portfolio/myoa-cookies": "#FFFFFF",
    "/portfolio/spark-fusion": "#FFFFFF",
    "/portfolio/bontrue-furniture": "#FFFFFF",
    "/portfolio/bhagavad-gita": "#FFFFFF",
    "/portfolio/weight-manage": "#FFFFFF",
    "/portfolio/agarwalbhavan-sweets": "#FFFFFF",
    "/portfolio/murari-sweets": "#FFFFFF",
    "/portfolio/singlas-sweets": "#FFFFFF",
    "/portfolio/savani-jewellery": "#FFFFFF",
    "/portfolio/bodyart": "#D4D7DA",
    "/portfolio/miamyally": "#F5ECFF",
    "/portfolio/gpullareddysweets": "#F3F3F3",
    "/portfolio/chaknow": "#F3F3F3",
    "/portfolio/vijayalakshmisilks": "#F3F3F3",
    "/portfolio/instantcashprice": "#C7CADB",
    "/portfolio/myredpalette": "#F3F3F3",
    "/portfolio": "#222222",
    "/portfolio/branding/abox": "#222222",
    "/portfolio/branding/terahash-solutions": "#FFFFFF",
    "/portfolio/branding/nourysh": "#FFFFFF",
}

export const svgColors = {
    "/portfolio/anand-sweets": "#9C8EFF",
    "/portfolio/yogabar": "#A1A7FF",
    "/portfolio/nourysh": "#E1E4FF",
    "/portfolio/dr-vaidya": "#BDF0FF",
    "/portfolio/giver-nutritions": "#FFD4AC",
    "/portfolio/bayla": "#EFFFBE",
    "/portfolio/chhappanbhog": "#BED2FF",
    "/portfolio/prakruti": "#90e3bd",
    "/portfolio/hyfun": "#DEFBCC",
    "/portfolio/hamdard": "#FBE5E6",
    "/portfolio/thenga": "#EDEAE0",
    "/portfolio/shistaka": "#F2E2BA",
    "/portfolio/sportsaber": "#EF6940",
    "/portfolio/dl-films": "#F5F1EB",
    "/portfolio/ubar": "#F5D8CB",
    "/portfolio/freshleaf": "#F5FAE2",
    "/portfolio/radhey-sweets": "#FFFBD2",
    "/portfolio/healthoxide": "#E8FAFF",
    "/portfolio/maa-and-baby": "#E8FAFF",
    "/portfolio/gogrub": "#E8FAFF",
    "/portfolio/ekki-bekki": "#E8FAFF",
    "/portfolio/mypro": "#E8FAFF",
    "/portfolio/teatox-life": "#E8FAFF",
    "/portfolio/aumex": "#E8FAFF",
    "/portfolio/greenfay": "#E8FAFF",
    "/portfolio/paperclip-and-co": "#E8FAFF",
    "/portfolio/naturare": "#E8FAFF",
    "/portfolio/fast-n-fry": "#E8FAFF",
    "/portfolio/neo-roots": "#E8FAFF",
    "/portfolio/within-beauty": "#A976FB",
    "/portfolio/uppercase": "#8AC5CF",
    "/portfolio/om-sweets": "#E89580",
    "/portfolio/healing-hands": "#AAF9FF",
    "/portfolio/yo-gathiya": "#F2B40D",
    "/portfolio/codifi": "#B394EA",
    "/portfolio/terahash-solutions": "#888888",
    "/portfolio/kutum-ayurveda": "#F2D6BB",
    "/portfolio/arospice": "#D4DFD9",
    "/portfolio/fone-network": "#5F61D7",
    "/portfolio/repeat-gud": "#C9C9C9",
    "/portfolio/nurdle": "#C9C9C9",
    "/portfolio/aurolab": "#B2D1D9",
    "/portfolio/technosport": "#B7B7B7",
    "/portfolio/westerville": "#B7B7B7",
    "/portfolio/myoa-cookies": "#F5DFBB",
    "/portfolio/spark-fusion": "#DFFF60",
    "/portfolio/bontrue-furniture": "#F3E5C5",
    "/portfolio/bhagavad-gita": "#C3C2C7",
    "/portfolio/weight-manage": "#CFCFCF",
    "/portfolio/agarwalbhavan-sweets": "#C3C2C7",
    "/portfolio/murari-sweets": "#5D8AC7",
    "/portfolio/singlas-sweets": "#A57760",
    "/portfolio/savani-jewellery": "#333333",
    "/portfolio/bodyart": "#D4D7DA",
    "/portfolio/miamyally": "#F5ECFF",
    "/portfolio/gpullareddysweets": "#F3F3F3",
    "/portfolio/chaknow": "#F9F9F9",
    "/portfolio/vijayalakshmisilks": "#F9F9F9",
    "/portfolio/instantcashprice": "#F9F9F9",
    "/portfolio/myredpalette": "#F3F3F3",
    "/portfolio": "#EFEBE7",
    "/portfolio/branding/abox": "#ffffff",
    "/portfolio/branding/terahash-solutions": "#ffffff",
    "/portfolio/branding/nourysh": "#A6A9E9",
    "/conversion-rate-optimization": "#004737",
}

export const CopyLinkColors = {
    "/portfolio/anand-sweets": "#FFFFFF",
    "/portfolio/yogabar": "#FFFFFF",
    "/portfolio/nourysh": "#012028",
    "/portfolio/dr-vaidya": "#FFFFFF",
    "/portfolio/giver-nutritions": "#2E170A",
    "/portfolio/bayla": "#FFFFFF",
    "/portfolio/chhappanbhog": "#FFFFFF",
    "/portfolio/prakruti": "#FFFFFF",
    "/portfolio/hyfun": "#FFFFFF",
    "/portfolio/hamdard": "#FFFFFF",
    "/portfolio/thenga": "#FFFFFF",
    "/portfolio/shistaka": "#FFFFFF",
    "/portfolio/sportsaber": "#FFFFFF",
    "/portfolio/dl-films": "#171717",
    "/portfolio/ubar": "#171717",
    "/portfolio/freshleaf": "#FFFFFF",
    "/portfolio/radhey-sweets": "#04272E",
    "/portfolio/healthoxide": "#171717",
    "/portfolio/maa-and-baby": "#171717",
    "/portfolio/gogrub": "#171717",
    "/portfolio/mypro": "#171717",
    "/portfolio/teatox-life": "#171717",
    "/portfolio/ekki-bekki": "#171717",
    "/portfolio/aumex": "#171717",
    "/portfolio/greenfay": "#171717",
    "/portfolio/paperclip-and-co": "#171717",
    "/portfolio/naturare": "#171717",
    "/portfolio/fast-n-fry": "#171717",
    "/portfolio/neo-roots": "#171717",
    "/portfolio/within-beauty": "#FFFFFF",
    "/portfolio/uppercase": "#FFFFFF",
    "/portfolio/om-sweets": "#FFFFFF",
    "/portfolio/healing-hands": "#FFFFFF",
    "/portfolio/yo-gathiya": "#171717",
    "/portfolio/codifi": "#FFFFFF",
    "/portfolio/terahash-solutions": "#FFFFFF",
    "/portfolio/kutum-ayurveda": "#171717",
    "/portfolio/arospice": "#FFFFFF",
    "/portfolio/fone-network": "#FFFFFF",
    "/portfolio/repeat-gud": "#171717",
    "/portfolio/nurdle": "#FFFFFF",
    "/portfolio/aurolab": "#FFFFFF",
    "/portfolio/technosport": "#FFFFFF",
    "/portfolio/westerville": "#FFFFFF",
    "/portfolio/myoa-cookies": "#FFFFFF",
    "/portfolio/spark-fusion": "#FFFFFF",
    "/portfolio/bontrue-furniture": "#FFFFFF",
    "/portfolio/bhagavad-gita": "#FFFFFF",
    "/portfolio/weight-manage": "#FFFFFF",
    "/portfolio/agarwalbhavan-sweets": "#FFFFFF",
    "/portfolio/murari-sweets": "#FFFFFF",
    "/portfolio/singlas-sweets": "#FFFFFF",
    "/portfolio/savani-jewellery": "#FFFFFF",
    "/portfolio/bodyart": "#D4D7DA",
    "/portfolio/miamyally": "#F5ECFF",
    "/portfolio/gpullareddysweets": "#FFFFFF",
    "/portfolio/chaknow": "#FFFFFF",
    "/portfolio/vijayalakshmisilks": "#FFFFFF",
    "/portfolio/instantcashprice": "#C7CADB",
    "/portfolio/myredpalette": "#FFFFFF",

    "/portfolio/ui/teatox-life": "#737270",
}

export const footerBG = {
    "/conversion-rate-optimization": "#004737",
}

export const footerColor = {
    "/conversion-rate-optimization": "#56F09F",
}

export const urls = {
    '/': '',
    '/contact': 'Contact',
    '/portfolio': 'Portfolio',
    '/service': 'Service',
    '/career': 'Career',
    '/about': 'About',
    '/clients': 'Clients',
    '/portfolio/anand-sweets': 'Anand Sweets',
    '/portfolio/yogabar': 'Yogabar',
    '/portfolio/nourysh': 'Nourysh',
    '/portfolio/dr-vaidya': 'Dr. Vaidya',
    '/portfolio/giver-nutritions': 'Giver Nutritions',
    '/portfolio/bayla': 'Bayla Skin',
    '/portfolio/chhappanbhog': 'Chhappanbhog',
    '/portfolio/prakruti': 'Prakruti',
    '/portfolio/hyfun': 'Hyfun',
    '/portfolio/hamdard': 'Hamdard',
    '/portfolio/thenga': 'Thenga',
    '/portfolio/shistaka': 'Shistaka',
    '/portfolio/sportsaber': 'Sport Saber',
    "/portfolio/dl-films": "DL Films",
    "/portfolio/ubar": "Ubar",
    "/portfolio/freshleaf": "Fresh Leaf",
    "/portfolio/radhey-sweets": "Radhey Sweets",
    "/portfolio/healthoxide": "Healthoxide",
    '/portfolio/maa-and-baby': 'Maa & Baby',
    '/portfolio/gogrub': 'Go Grub',
    "/portfolio/mypro": "My Pro",
    "/portfolio/teatox-life": "TeaTox Life",
    '/portfolio/ekki-bekki': 'Ekki Bekki',
    '/portfolio/aumex': 'Aumex',
    '/portfolio/greenfay': 'Green Fay',
    '/portfolio/paperclip-and-co': 'Paperclip & Co',
    '/portfolio/naturare': 'Naturare',
    '/portfolio/fast-n-fry': 'Fast & Fry',
    '/portfolio/neo-roots': 'Neo Roots',
    "/portfolio/within-beauty": "Within Beauty",
    "/portfolio/uppercase": "Uppercase",
    "/portfolio/om-sweets": "Om Sweets & Snacks",
    "/portfolio/healing-hands": "Healing hands",
    "/portfolio/yo-gathiya": "Yo Gathiya",
    "/portfolio/codifi": "CodiFi",
    "/portfolio/terahash-solutions": "Terahash Solutions",
    "/portfolio/kutum-ayurveda": "Kutum Ayurveda",
    "/portfolio/arospice": "Arospice",
    "/portfolio/fone-network": "Fone Network",
    "/portfolio/repeat-gud": "Repeat Gud",
    "/portfolio/nurdle": "Nurdle",
    "/portfolio/aurolab": "AuroLab",
    "/portfolio/technosport": "Techno Sport",
    "/portfolio/westerville": "Westerville",
    "/portfolio/myoa-cookies": "Myoa Cookies",
    "/portfolio/spark-fusion": "Spark Fusion",
    "/portfolio/bontrue-furniture": "Bontrue Furniture",
    "/portfolio/bhagavad-gita": "Bhagavad-Gita",
    "/portfolio/weight-manage": "Weight Manage",
    "/portfolio/agarwalbhavan-sweets": "Agarwal Bhavan Sweets",
    "/portfolio/murari-sweets": "Murari Sweet",
    "/portfolio/singlas-sweets": "Singla’s Sweets",
    "/portfolio/savani-jewellery": "Savani Jewellery",
    "/portfolio/bodyart": "Body Art",
    "/portfolio/miamyally": "Mia My Ally",
    "/portfolio/gpullareddysweets": "G.Pulla Reddy Sweets",
    "/portfolio/chaknow": "Chak Now",
    "/portfolio/vijayalakshmisilks": "Vijaya Laxmi Silk",
    "/portfolio/instantcashprice": "Instant cash Price",
    "/portfolio/myredpalette": "My Red Palette",

    '/services/seo': 'SEO',
    '/services/web-development': 'Web Development',
    '/services/shopify': 'Shopify',
    '/services/visual-identity': 'Visual Identity',
    '/services/ui-ux': 'UI/UX',
    '/services/web-design': 'Web Design',
    '/services/for-startup': 'For Startup',
    '/services/ab-testing': 'A/b Testing',
    '/services/3d-render': '3D Render',
    '/portfolio/branding/abox': 'aBox',
    '/portfolio/branding/terahash-solutions': 'Terahash Solutions',
    '/portfolio/branding/nourysh': 'Nourysh',
} 